<template>
  <base-material-modal
    v-model="modal_horario"
    icon="mdi-animation-outline"
    title="Añadir horario"
    width="700"
    @input="close"
  >
    <template v-slot:content>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1" editable>
            Configuración de Fechas
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step editable :complete="e1 > 2" step="2">
            Horarios de aperturas
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step step="3"></v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            Selecione las fechas de duración de este horario
            <v-row>
              <v-col cols="4">
                <v-menu
                  ref="date_start"
                  v-model="date_start"
                  :close-on-content-click="false"
                  :return-value.sync="horario.date_start"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="horario.date_start"
                      label="Fecha de inicio"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="es-ES"
                    :max="horario.date_end"
                    :min="date"
                    v-model="horario.date_start"
                    no-title
                    scrollable
                    :first-day-of-week="1"
                  >
                    <v-spacer></v-spacer>

                    <v-btn text color="primary" @click="date_start = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.date_start.save(horario.date_start)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-menu
                  ref="date_end"
                  v-model="date_end"
                  :close-on-content-click="false"
                  :return-value.sync="horario.date_end"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="horario.date_end"
                      label="Fecha final"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    locale="es-ES"
                    v-model="horario.date_end"
                    no-title
                    :min="horario.date_start"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="date_end = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.date_end.save(horario.date_end)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4" class="ml-auto">
                <v-menu
                  ref="menuIntHorario"
                  v-model="intHorario"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="horario.time_booking"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="horario.time_booking"
                      label="Duracion de horarios en minutos"
                      prepend-icon="mdi-clock-time-two-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="intHorario"
                    v-model="horario.time_booking"
                    format="24hr"
                    full-width
                    @click:minute="
                      $refs.menuIntHorario.save(horario.time_booking)
                    "
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="selectedDays"
                  :items="days"
                  item-text="text"
                  item-value="value"
                  label="Días seleccionados"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            selectedDays.length > 0 ? 'indigo darken-4' : ''
                          "
                        >
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Todos los días</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  prepend-inner-icon="mdi-playlist-edit"
                  v-model="horario.max_bookings"
                  type="number"
                  hint="Cantidad máxima de reservas disponibles por horario."
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  prepend-inner-icon="mdi-playlist-edit"
                  v-model="horario.max_person"
                  type="number"
                  hint="Cantidad máxima de personas por reserva."
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn color="primary" @click="e1 = 2">
              Continuar
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-row
              v-for="(schedule, index) in attention_schedule"
              :key="index"
              align="center"
            >
              <v-col cols="6" style=" flex: 0 1 auto;">
                <v-menu
                  :ref="'menuHoraAper'+index"
                  :v-model="attention_schedule[index].modalOpen"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="schedule.open"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="schedule.open"
                      label="Hora de inicio de horario"
                      prepend-icon="mdi-clock-time-two-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    :v-if="attention_schedule[index].modalOpen"
                    v-model="schedule.open"
                    :max="schedule.closed"
                    format="24hr"
                    full-width
                    @click:minute="saveRefHorarioApert(index,'open')"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" style="flex: 0 1 auto;">
                <v-menu
                  :ref="'menuHoraCierre'+index"
                  v-model="attention_schedule[index].modalClose"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="schedule.closed"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="schedule.closed"
                      label="Hora de fin de horario"
                      prepend-icon="mdi-clock-time-two-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    :v-if="attention_schedule[index].modalClose"
                    v-model="schedule.closed"
                    :min="schedule.open"
                    format="24hr"
                    full-width
                    @click:minute="saveRefHorarioApert(index,'closed')"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-btn
                color="primary"
                v-if="attention_schedule.length > 1"
                @click="removeApertura(index)"
                >Remover apertura</v-btn
              >
            </v-row>
            <v-btn color="primary" @click="addApertura"
              >Agregar nueva apertura</v-btn
            >

            <v-btn color="primary" @click="e1 = 3">
              Continuar
            </v-btn>

            <v-btn text @click="e1 = e1 - 1">Atras</v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card class="mb-12" color="">
              <span>
                Fechas: {{ horario.date_start }} - {{ horario.date_end }} </span
              ><br />
              <span>Intervalos de tiempo: {{ horario.time_booking }}</span
              ><br />
              <span
                >Maxima cantidad de personas por reserva
                {{ horario.max_person }}</span
              ><br />
              <span
                >Maxima cantidad de reservas por horario
                {{ horario.max_bookings }}</span
              ><br />

              <span>Horarios de atención: {{ attention_schedule }}</span
              ><br />
            </v-card>
            <v-btn color="primary" @click="saveHorario">
              Crear horario
            </v-btn>
            <v-btn text @click="e1 = e1 - 1">Atras</v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </base-material-modal>
</template>

<script>
export default {
  name: "CreateHorario",
  props: ["modal_horario", "restaurant_id"],
  data() {
    return {
      e1: 1,
      horario: {
        date_start: "",
        date_end: "",
        time_booking: "",
        max_bookings: "",
        max_person: "",
        days_repeat: "",
        restaurant_id: "",
        attention_schedule: [
          {
            open: "",
            closed: "",
            modalOpen:false,
            modalClose:false
          }
        ]
      },
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      date_start: false,
      date_end: false,
      /* days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], */
      days: [
        { text: "Lunes", value: "Mon" },
        { text: "Martes", value: "Tue" },
        { text: "Miercoles", value: "Wed" },
        { text: "Jueves", value: "Thu" },
        { text: "Viernes", value: "Fri" },
        { text: "Sábado", value: "Sat" },
        { text: "Domingo", value: "Sun" }
      ],
      selectedDays: [],
      attention_schedule: [
        {
          open: null,
          closed: null,
          modalOpen:false,
          modalClose:false
        }
      ],
      intHorario: false
    };
  },
  methods: {
    init() {
      this.horario = {
        date_start: "",
        date_end: "",
        time_booking: "",
        max_bookings: "",
        max_person: "",
        days_repeat: "",
        restaurant_id: ""
      };
      this.attention_schedule = [
        {
          open: null,
          closed: null,
          modalOpen:false,
          modalClose:false
        }
      ];
      this.selectedDays = [];
      this.e1= 1;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllDays) {
          this.selectedDays = [];
        } else {
          this.selectedDays = this.days.slice();
        }
      });
    },
    addApertura() {
      this.attention_schedule.push(this.newSchedule());
    },
    newSchedule() {


      let schedule = {
        open: null,
        closed: null,
        modalOpen:false,
        modalClose:false
      };
      return schedule;
    },
    removeApertura(key) {
      this.attention_schedule.splice(key, 1);
    },
    close: function(val) {
      this.init();
      this.$emit("closeModal", val);
    },

    async saveHorario() {
      let data = this.horario;
      data.attention_schedule = this.attention_schedule;
      data.restaurant_id = this.restaurant_id;
      data.days_repeat = this.selectedDays;
      data.time_booking = this.convertHours(data.time_booking);
      await this.$api.post("api/schedule", data).then(res => {
        this.$emit('horarioCreado');
        this.close(false);
      });
      this.close(false);
    },
    convertHours(hours) {
      var d = new Date("0001-01-01T" + hours + ":00");
      var minutos = d.getHours() * 60 + d.getMinutes();
      return minutos;
    },
    saveRefHorarioApert(index,horario){
      if (horario === 'open') {
        let name = 'menuHoraAper'+index;
        this.$refs[name][0].save(this.attention_schedule[index].open);
      }
      if (horario === 'closed') {
        let name = 'menuHoraCierre'+index;
        this.$refs[name][0].save(this.attention_schedule[index].closed);
      }
    }
  },
  computed: {
    likesAllDays() {
      return this.selectedDays.length === this.days.length;
    },
    likesSomeDay() {
      return this.selectedDays.length > 0 && !this.likesAllDays;
    },
    icon() {
      if (this.likesAllDays) return "mdi-close-box";
      if (this.likesSomeDay) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  }
};
</script>
