<template>
  <v-container fluid tag="section">
    <v-row align-content="center">
      <v-col cols="12" sm="7" md="7" lg="7" align-self="end" class="pb-1">
        <v-btn color="primary" @click="modal_horario = true"
          >Crear horario</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card flat class="mt-0" v-if="ready">
          <v-row class="fill-height">
            <v-col cols="12">
              <v-sheet tile height="54" color="grey lighten-3" class="d-flex">
                <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-select
                  v-model="weekday"
                  :items="weekdays"
                  dense
                  outlined
                  hide-details
                  label="Dias de semana"
                  class="ma-2"
                ></v-select>
                <v-btn
                  v-if="type === 'week' || type === 'day'"
                  dense
                  outlined
                  label="volver"

                  class="ma-2"
                  @click="type = 'month'"
                >
                  Mes
                </v-btn>
                <v-spacer></v-spacer>
                <v-menu right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dense
                      outlined
                      hide-details

                      class="ma-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Dia</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Semana</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Mes</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn icon class="ma-2" @click="$refs.calendar.next()">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-sheet>
              <v-sheet height="600">
                <v-calendar
                  ref="calendar"
                  v-model="value"
                  :weekdays="weekday"
                  :type="type"
                  :events="events"
                  :event-overlap-mode="mode"
                  :event-overlap-threshold="30"
                  event-color="blue"
                  locale="es-ES"
                  :weekday-format="myDayFormat"
                  @click:event="showEvent"
                  @change="getEvents"
                  @click:more="viewDay"
                  @click:date="viewDay"
                  :short-months="shortMonths"
                >
                  <template v-slot:event="{ eventParsed }">
                    <v-badge
                      v-if="
                        reservationExists(
                          eventParsed.start.date,
                          eventParsed.start.time
                        )
                      "
                      color="green"
                      :content="
                        countReservation(
                          eventParsed.start.date,
                          eventParsed.start.time
                        )
                      "
                      offset-x="-100"
                      offset-y="6"
                      bordered
                    >
                    </v-badge>
                    <template>
                      {{ eventParsed.start.time }} -
                      {{ eventParsed.end.time }}
                    </template>
                  </template>
                </v-calendar>
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  offset-x
                >
                  <v-card color="grey lighten-4" flat>
                    <v-toolbar :color="selectedEvent.color" dark>
                      <v-toolbar-title
                        v-html="selectedEvent.name"
                      ></v-toolbar-title>
                      <v-spacer></v-spacer>
                      <span v-if="selectedEvent.color === 'red'">No hay disponibilidad para reservar</span>
                      <v-btn icon @click="confirmDialog(selectedEvent.id_horario,'schedule')">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                      <v-btn icon :disabled="selectedEvent.color === 'red'" @click="openReservation = true">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text v-if="reservationDay.length > 0">
                      <v-row>
                        <v-col
                          v-for="(res, index) in reservationDay"
                          :key="index"
                          cols="12"
                        >
                          <v-card>
                            <v-list-item two-line>
                              <v-list-item-content>
                                <div class="overline mb-4">
                                  {{ res.start_time }} - {{ res.end_time }}
                                </div>
                                <v-list-item-title class="headline mb-1">
                                  {{ res.name }}
                                  {{ res.last_name }}</v-list-item-title
                                >
                                <v-list-item-subtitle>{{
                                  res.email
                                }}</v-list-item-subtitle>
                              </v-list-item-content>

                              <v-list-item-content>
                                <div class="overline mb-4">
                                  n° comensales: {{ res.diners }}
                                </div>
                              </v-list-item-content>
                            </v-list-item>

                            <v-card-actions>
                              <v-btn text>
                                <v-icon>mdi-pencil</v-icon> </v-btn>
                              <v-btn text @click="confirmDialog(res.id,'booking')">
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text v-else>
                      <span>No hay reservas</span>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn
                        text
                        color="secondary"
                        @click="selectedOpen = false"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Confirmar borrados -->
    <template>
      <div class="text-center">
        <v-dialog
          v-model="dialogConfirm"
          width="500"
        >
          <v-card>
            <v-card-title class="headline grey lighten-2">
              ¿Confirma que desea borrar?
            </v-card-title>

            <v-card-text>
               {{messageDelete}}
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="deleteItem"
              >
                Si
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <!-- Crear horario -->
    <crearHorario
      @closeModal="updateModalHorario"
      :modal_horario="modal_horario"
      :restaurant_id="restaurant_id"
      @horarioCreado="horarioCreado"
    />
    <!-- Crear reserva -->
    <crearReservation
      @closeModalReserva="updateModalReservation"
      @updateAll="init"
      :openReservation="openReservation"
      :restaurant_id="restaurant_id"
      :eventForReserva="eventForReserva"
      :url="url"
    />
  </v-container>
</template>

<script>
import crearHorario from "./createhorario";
import crearReservation from "../../../components/CrearReservation";
export default {
  components: { crearHorario, crearReservation },
  name: "Horarios",
  data: () => ({
    type: "month",
    typeToLabel: {
      month: "Mes",
      week: "Semana",
      day: "Dia"
    },
    shortMonths: false,
    mode: "stack",
    weekday: [1, 2, 3, 4, 5, 6, 0],
    weekdays: [
      { text: "Dom - Sab", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Lun - Dom", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Lun - Vie", value: [1, 2, 3, 4, 5] },
      { text: "Lun, Mie, Vie", value: [1, 3, 5] }
    ],
    value: "",
    events: [],
    search: "",
    restaurant_id: null,
    url_edit: "",
    horarios: [],
    modal_horario: false,
    saving_horario: false,
    ready: false,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    reservations: [],
    reservationDay: [],
    openReservation: false,
    eventForReserva: {},
    redirect: "",
    url: null,
    horarioIdDelete:null,
    dialogConfirm:false,
    itemDelete:{id:null,type:''}
  }),
  created() {
    if (this.$store.getters["auth/isAdmin"]) {
      this.restaurant_id = this.$route.params ? this.$route.params.id : "";
      this.url_edit = `/tc-admin/horarios/${this.restaurant_id}/administrar/horarios/`;
      this.redirect = `/tc-admin/horarios/${this.dishe.restaurant_id}/administrar/platos`;
    } else {
      this.restaurant_id = this.$store.getters["auth/restaurant"];
      this.url_edit = `/tc-admin/horarios/`;
      this.redirect = "/tc-admin/horarios";
    }
    this.init();

    this.$store.commit("SET_OVERLAY", false);
  },
  methods: {
    init() {
      this.getHorarios();
      this.getReservations();
    },
    async getReservations() {
      let url = "api/booking/" + this.restaurant_id;
      this.$api
        .get(url)
        .then(res => {
          this.reservations = res.data.reservation;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async getHorarios() {
      var form = new FormData();
      form.append("restaurant_id", this.restaurant_id);

      this.$api.get("api/schedule/" + this.restaurant_id).then(res => {

        var data = res.data.data;
        this.horarios = data;

        this.ready = true;
      });
    },
    updateEvents(){
      var events = [];
      var horario = this.horarios;

      for (const fecha in horario) {
        for (const horas in horario[fecha]) {
          var fecha_inicio = new Date(
            fecha + "T" + horario[fecha][horas].start_booking + ":00"
          );
          var fecha_final = new Date(
            fecha + "T" + horario[fecha][horas].end_booking + ":00"
          );
          const h = {
            name: "Reservaciones",
            start: fecha_inicio,
            max_person:horario[fecha][horas].max_person,
            max_booking:horario[fecha][horas].max_booking,
            end: fecha_final,
            id_horario:horario[fecha][horas].id_horario,
            color: "blue",
            timed: true,

          };
          events.push(h);
        }
      }
      this.events = events;

    },
    getEvents({ start, end }) {
      this.updateEvents();
    },
    updateModalHorario: function(val) {
      this.modal_horario = val;
    },
    updateModalReservation: function(val) {
      this.openReservation = val;
    },
    showEvent({ nativeEvent, event }) {

      this.reservationDay = [];
      let d = event.start;
      let dend = event.end;

      let fecha =
        d.getFullYear() +
        "-" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + d.getDate()).slice(-2);
      let hora_inicio = ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
      let hora_final = ("0" + dend.getHours()).slice(-2) +":" + ("0" + dend.getMinutes()).slice(-2);
      this.eventForReserva = {
        start_time: hora_inicio,
        date: fecha,
        end_time: hora_final,
        max_booking:event.max_booking,
        max_person:event.max_person,
        id_horario:event.id_horario
      };

      let arrayReservation = [];

      this.reservations.forEach(element => {
        if (element.date == fecha && element.start_time == hora_inicio) {
          arrayReservation.push(element);
        }
      });
      this.reservationDay = arrayReservation;
      let cant_reservation = arrayReservation.length;
      if (cant_reservation >= event.max_booking) {
        event.color = 'red';
      }
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    reservationExists(date, time_start) {
      let result = false;

      this.reservations.forEach(element => {

        if (element.date == date && element.start_time == time_start) {
          result = true;
        }
      });
      return result;
    },
    countReservation(date, time_start) {
      let count = 0;
      this.reservations.forEach(element => {
        if (element.date == date && element.start_time == time_start)
        {

          count += 1;
        }
      });
      return count;
    },
    myDayFormat(d) {
      if (d.weekday === 0) {
        return "Dom";
      }
      if (d.weekday === 1) {
        return "Lun";
      }
      if (d.weekday === 2) {
        return "Mar";
      }
      if (d.weekday === 3) {
        return "Mie";
      }
      if (d.weekday === 4) {
        return "Jue";
      }
      if (d.weekday === 5) {
        return "Vie";
      }
      if (d.weekday === 6) {
        return "Sab";
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    confirmDialog(id,type){
      this.itemDelete = {
        id:id,
        type:type
      };
      this.dialogConfirm = true;
    },
    deleteItem(){
      let url='';
      let item = this.itemDelete;
      if (item.type === 'booking') {
        url = "api/booking/" + item.id;
      }
      if (item.type === 'schedule') {
        url = "api/schedule/" + item.id;
      }
      this.$api.delete(url).then(res=>{
          this.$router.go();
      });
      this.dialogConfirm = false;
    },
    horarioCreado(){
      //this.$router.go();
      this.getHorarios();
    }
  },
  computed:{
    messageDelete:function(){
      if (this.itemDelete.type === 'schedule') {
        return '¿Está seguro que desea borrar los horarios para esta fecha? esta acción no se puede deshacer.';
      }
      if (this.itemDelete.type === 'booking') {
        return '¿Está seguro que desea borrar esta reserva? Está acción no se puede deshacer.';
      }
      return '';
    }
  },
  watch:{
    horarios:function(){
      this.updateEvents()
    }
  }
};
</script>
<style lang="sass" scope>
.v-calendar
  .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
    color: #000
    &:hover
      color: #000
</style>
